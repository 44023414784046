import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';



const Footer = () => {
  const [aboutItems, setAboutItems] = useState([]);
  const [servicesItems, setServicesItems] = useState([]);
  const [latestPosts, setLatestPosts] = useState([]);
  const [newsSection, setNewsSection] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [subscribeInfo, setSubscribeInfo] = useState([]);

  

  useEffect(() => {
    // Replace with your actual API endpoint
    axios.get('https://backend.kbascontracting.com/AboutFooter/list')
      .then(response => {
        if (response.data && response.data.data) {
          setAboutItems(response.data.data); // Assuming this is an array
        }
      })
      .catch(error => console.error('Error fetching about items:', error));

      axios.get('https://backend.kbascontracting.com/ServicesFooter/list')
      .then(response => {
        if (response.data && response.data.data) {
          setServicesItems(response.data.data);
        }
      })
      .catch(error => console.error('Error fetching services items:', error));
      
      axios.get('https://backend.kbascontracting.com/News/list')
      .then(response => {
        if (response.data && response.data.data) {
          setLatestPosts(response.data.data);
        }
      })
      .catch(error => console.error('Error fetching latest posts items:', error));
  
      axios.get('https://backend.kbascontracting.com/NewsSection/list') // Update this URL based on your API
  .then(response => {
    if (response.data && response.data.data) {
      setNewsSection(response.data.data);
    }
  })
  .catch(error => console.error('Error fetching news section items:', error));

  axios.get('https://backend.kbascontracting.com/social/list')
  .then(response => {
    setSocialLinks(response.data.data);
  })
  .catch(error => {
    console.error('Error fetching social links', error);
  });

  axios.get('https://backend.kbascontracting.com/Subscribe/list') // Update this URL based on your API
    .then(response => {
      if (response.data && response.data.data) {
        setSubscribeInfo(response.data.data);
      }
    })
    .catch(error => console.error('Error fetching subscription info:', error));
}, []);

  return (
    <footer className=" text-white py-20 " style={{backgroundColor:'#202020'}}>
      <div className="container mx-auto px-4 lg:px-60 flex flex-wrap justify-center space-x-0 ">
        {/* About Section */}
        {aboutItems.length > 0 && (
        <div className="w-full lg:w-1/3 mb-6 lg:mb-0 pl-14">
          {aboutItems.map((item, index) => (
           <div to="/about" key={index}>
              <h4 className="font-bold mb-4">{item.title}</h4>
              <p>{item.description}</p>
              </div>
          ))}
          
          {/* <div className="flex mt-10 ">
          {socialLinks.map(link => (
  <a key={link.id} href={link.url} target="_blank" rel="noopener noreferrer" className="p-2 mr-1" style={{ backgroundColor: '#333333', borderRadius: '4px' }}>
    <img src={`https://backend.kbascontracting.com/${link.image}`} alt={link.name} className="h-4 w-4 mr-2" />
  </a>
  ))}
  </div> */}

        </div>
 )}
      {/* Services Section */}
        {servicesItems.length > 0 && (
          <div className="w-full lg:w-1/3 mb-6 lg:mb-0 pl-14">
            {servicesItems.map((item, index) => (
              <div to="/services" key={index}>
                    
                <h4 className="font-bold mb-4">{item.title}</h4>
                
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        )}


    {/* Latest Posts Section */}
   {/* Latest Posts Section */}
{/* {newsSection.length > 0 && latestPosts.length > 0 && (
  <div className="w-full lg:w-1/4 mb-6 lg:mb-0">
          <Link to="/news">
    <h4 className="font-bold mb-6"> {latestPosts[0].title}</h4>
    </Link>
    <div className="space-y-4">
      {newsSection.slice(0, 3).map((newsItem, index) => (
                  <Link to="/news" key={index}>
                  <div className="flex items-center mb-5">
          <img src={`https://backend.kbascontracting.com/${newsItem.mainImage}`} className="mr-2 bg-gray-400 h-10 w-10" alt={`News ${index}`} />
          <div>
            <p className="font-semibold">{newsItem.title}</p>
           
          </div>
        </div>
        </Link>
      ))}
    </div>
  </div>
)} */}

    {/* Subscribe Section */}
    {/* {subscribeInfo.length > 0 && (
  <div className="w-full lg:w-1/4">
    {subscribeInfo.map((item, index) => (
      <div key={index}>
        <h4 className="font-bold mb-3">{item.title}</h4>
        <p>{item.description}</p>
      </div>
    ))}
    <input type="email" placeholder="Email Address" className="mt-4 p-2 w-full border-2 border-gray-400" style={{backgroundColor:'#202020'}}/>
    <button className="mt-4 p-2 w-full bg-orange-500 border-none">Subscribe</button>
  </div>
)} */}
    
    <div className="w-full lg:w-1/4 mb-6 lg:mb-0 pl-14">
    <h4 className="font-bold mb-4">Follow Us</h4>
    <div className="flex mt-10 ">
          {socialLinks.map(link => (
            <a key={link.id} 
  // href={link.url} target="_blank" 
  rel="noopener noreferrer" 
  className="p-3 mr-2" 
  style={{ 
    backgroundColor: '#ffffff', 
    borderRadius: '4px',
    display: 'flex',      // Enable Flexbox
    justifyContent: 'center', // Center horizontally
    alignItems: 'center'  // Center vertically
  }}>
    <img src={`https://backend.kbascontracting.com/${link.image}`} alt='' className="h-4 w-4 " />
</a>

  ))}
  </div>
  </div>

  </div>

</footer>

);
};

export default Footer;