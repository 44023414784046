import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; 

import ArrowLeft from '../Images/left.png';
import ArrowRight from '../Images/right.png';

const LatestNews = () => {
  const [sectionTitle, setSectionTitle] = useState('');
  const [sectionDescription, setSectionDescription] = useState('');
  const [newsItems, setNewsItems] = useState([]);
  const [logos, setLogos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 3; // Number of news items to display at a time

  useEffect(() => {
    axios.get('https://backend.kbascontracting.com/News/list') 
      .then(response => {
        if (response.data && response.data.data && response.data.data.length > 0) {
          const latestNews = response.data.data[0];
          setSectionTitle(latestNews.title);
          setSectionDescription(latestNews.paragraph);
          setNewsItems(latestNews.items);
        }
      })
      .catch(error => console.error('Error fetching latest news', error));

    axios.get('https://backend.kbascontracting.com/NewsSection/list')
      .then(response => {
        if (response.data && response.data.data) {
          setNewsItems(response.data.data);
        }
      })
      .catch(error => console.error('Error fetching news items', error));

    axios.get('https://backend.kbascontracting.com/Partners/list')
      .then(response => {
        if (response.data && response.data.data) {
          setLogos(response.data.data);
        }
      })
      .catch(error => console.error('Error fetching logos', error));
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + itemsPerPage;
      return newIndex < newsItems.length ? newIndex : prevIndex;
    });
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - itemsPerPage;
      return newIndex >= 0 ? newIndex : prevIndex;
    });
  };

  const renderNewsItems = () => {
    if (!newsItems || newsItems.length === 0) {
      return <p></p>;
    }

    const itemsToShow = newsItems.slice(currentIndex, currentIndex + itemsPerPage);
    return itemsToShow.map((item, index) => (
      <Link to="/news" key={index}>
      <div className="space-y-2 text-center md:text-left">
        <img src={`https://backend.kbascontracting.com/${item.mainImage}`} alt={`Project ${index + 1}`} className="object-cover w-full h-48 rounded" />
        <h3 className="text-lg font-semibold">{item.title}</h3>
        <p className="text-gray-500 text-sm">{item.paragraph}</p>
      </div>
    </Link>
  ))}

  return (
    <div className="bg-white py-2">
      {/* <div className="container mx-auto max-w-4xl px-12">
        
        <div className="mb-6">
          <h2 className="text-3xl text-center text-orange-500">{sectionTitle}</h2>
          <p className="text-md text-center text-gray-600 mt-4">{sectionDescription}</p>
          <hr className="border-2 w-14 border-orange-500 mx-auto mt-3" />
        </div>

        
        <div className="flex justify-end mb-4">
          <button aria-label="Previous" className="bg-orange-500 p-0.5 m-1" onClick={handlePrevious}>
            <img src={ArrowLeft} alt="Previous" className="h-6 w-6" />
          </button>
          <button aria-label="Next" className="bg-orange-500 p-0.5 m-1" onClick={handleNext}>
            <img src={ArrowRight} alt="Next" className="h-6 w-6" />
          </button>
        </div>

        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {renderNewsItems()}
        </div>
      </div> */}

      {/* Logos Section */}
      <div className="flex flex-col sm:flex-row justify-center items-center xl:space-x-20  xl:mt-0 xl:mb-5 sm:overflow-x-auto">
        {logos.map((item, index) => (
          <div key={index} className="flex-shrink-0 text-center mx-2 my-4 ">
            <img src={`https://backend.kbascontracting.com/${item.path}`} alt={`Logo ${index + 1}`} className="w-120 h-auto" />
            <p className="mt-2 text-sm">{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestNews;
