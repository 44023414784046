import React from 'react';
import image1 from '../Images/7.png'; 
import image2 from '../Images/silhouette-of-engineer-looking-at-construction-site-engineering-concept-double-exposure-generative-ai-free-photo.jpg'; 
import image3 from '../Images/Untitled.jpg';

const About = () => {
  return (
    <>

<div className="flex flex-col px-5  max-w-7xl mx-auto my-5 mt-20 xl:px-20">
      <h2 className="text-2xl font-bold uppercase tracking-wider text-orange-500">About us</h2>
      <h1 className="text-xl font-bold text-gray-900 mt-7">
        KBAS Contracting &<br />Construction

      </h1>
      <hr className="border-t-4 border-orange-500 w-1/5  mt-7" />
    </div>
      {/* First section with text on the left */}
      <div className="flex flex-col lg:flex-row justify-between items-center max-w-7xl mx-auto my-5 xl:mt-0 xl:px-20">
        <div className="lg:w-1/2 p-8">
          <h2 className="text-2xl font-semibold mb-4 ">KBAS Construction Contracting - A Legacy of Excellence, A New Chapter Unveiled</h2>
          <p className="text-gray-700 mb-4" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
          In 2023, KBAS Construction Contracting embarked on a transformative journey, taking over the esteemed Pan East General Contracting Ltd, an Iraqi company with roots tracing back to the 1980s. Originally formed through the takeover of the local activities of an international contracting company in Qatar in 1976, Pan East established a formidable presence in Iraq, focusing on engineered construction with a commitment to excellence.
          </p>
        </div>
        <div className="lg:w-1/3 xl:pr-8 xl:mt-0 ">
          <img src={image1} alt="Our Unique Process" className="w-96 h-64 rounded-lg" />
        </div>
      </div>

      {/* Second section with text on the right */}
      <div className="flex flex-col lg:flex-row-reverse justify-between items-center max-w-7xl mx-auto my-5 xl:mt-0 xl:px-20">
  <div className="lg:w-1/2 p-8">
    <h2 className="text-2xl font-semibold mb-4">Pan East General Contracting Ltd: A Rich Heritage</h2>
    <p className="text-gray-700 mb-4" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
      Founded on the principles of quality, precision, and engineering excellence, Pan East General Contracting Ltd became a significant player in the Iraqi construction and contracting landscape. The company's inception in the 1980s marked the continuation of local activities acquired from an international contracting giant in Qatar. The complete transfer of local management, staff, and equipment from the European company ensured a seamless transition, maintaining a steadfast emphasis on engineered construction.

      For decades, Pan East contributed to the development of Iraq's infrastructure, earning a reputation for reliability, professionalism, and a commitment to delivering projects of the highest caliber. With a legacy rooted in the 1980s, Pan East established itself as a trusted partner, providing construction and contracting services with a focus on precision and engineering finesse.
    </p>
  </div>
  <div className="lg:w-1/3 xl:pr-0 xl:pl-8 xl:mb-40">
    <img src={image2} alt="Award-Winning Work" className="w-96 h-64 rounded-lg" />
  </div>
</div>



      <div className="flex flex-col lg:flex-row justify-between items-center max-w-7xl mx-auto my-5 xl:mt-0 xl:px-20 mb-10">
        <div className="lg:w-1/2 p-8">
          <h2 className="text-2xl font-semibold mb-4 ">KBAS Construction Contracting: A New Chapter Unveiled</h2>
          <p className="text-gray-700 mb-4" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
          In 2023, KBAS Construction Contracting proudly took the reins of Pan East in QATAR, bringing a fresh perspective, innovative solutions, and a commitment to building upon the rich heritage established over the years. KBAS Construction Contracting is a prominent and dynamic construction and engineering firm. As a company dedicated to transforming visions into reality, we pride ourselves on delivering exceptional services across a diverse range of sectors. Our commitment to excellence, innovation, and client satisfaction sets us apart as a trusted partner in the development and execution of projects.
          </p>
        </div>
        <div className="lg:w-1/3 xl:pr-8 xl:mb-14">
          <img src={image3} alt="Our Unique Process" className="w-96 h-64 rounded-lg" />
        </div>
      </div>


      <div className="bg-gray-100 py-20 ">
        <div className="max-w-7xl mx-auto px-4 lg:px-8">
          <h2 className="text-3xl font-semibold text-center mb-10 text-gray-800 ">Why Choose KBAS</h2>
          <div className="grid md:grid-cols-2 gap-6 mt-20 mb-10">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-orange-500 mb-2">Expertise</h3>
              <p className="text-gray-700">Our team comprises seasoned professionals with a proven track record in the industry.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-orange-500 mb-2">Innovation</h3>
              <p className="text-gray-700">We leverage cutting-edge technologies and industry best practices to deliver innovative solutions.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-orange-500 mb-2">Commitment</h3>
              <p className="text-gray-700">We are dedicated to meeting and exceeding client expectations through unwavering commitment to quality and excellence.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md ">
              <h3 className="text-xl font-semibold text-orange-500 mb-2">Local Insight, Global Standards</h3>
              <p className="text-gray-700">With a deep understanding of the local market and global best practices, we offer a unique blend of insight and expertise.</p>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default About;
