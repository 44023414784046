import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="max-w-4xl mx-auto p-4 mt-20 my-20">
      <h1 className="text-3xl font-bold mb-20">Terms and Conditions for KBAS Construction Contracting</h1>
      
    
      
      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10">1. Introduction</h2>
      <p className="mb-4">These terms and conditions outline the rules and regulations for the use of KBAS Construction Contracting's services and website.</p>
      
      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10">2. Use of Our Services</h2>
      <p className="mb-4">By accessing and using our services, you agree to comply with these terms and conditions, as well as all applicable laws and regulations.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10">3. Changes to Terms</h2>
      <p className="mb-4">We reserve the right to modify these terms at any time. We will notify you of any changes by updating the terms on our website.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">4. Service Availability</h2>
      <p className="mb-4">Our services are subject to availability, and we reserve the right to withdraw or amend our services without notice.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10">5. Intellectual Property</h2>
      <p className="mb-4">All content provided on our website and in our services, including text, graphics, logos, images, and software, is the property of KBAS Construction Contracting or its content suppliers and is protected by intellectual property laws.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10">6. Limitation of Liability</h2>
      <p className="mb-4">KBAS Construction Contracting will not be liable for any damages arising from the use or inability to use our services.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10">7. Governing Law</h2>
      <p className="mb-4">These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction in which KBAS Construction Contracting operates.</p>

      
    </div>
  );
}

export default TermsAndConditions;
