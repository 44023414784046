import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; 

const FeatureSection = () => {
  const [sections, setSections] = useState([]);
  const [backgroundImagePath, setBackgroundImagePath] = useState('');

  useEffect(() => {
    // Fetching feature sections
    axios.get('https://backend.kbascontracting.com/featureSection/list')
      .then(response => {
        setSections(response.data.data);
      })
      .catch(error => {
        console.error('There was an error fetching the feature sections', error);
      });

    // Fetching background image
    axios.get('https://backend.kbascontracting.com/backgroundImage/list')
      .then(response => {
        setBackgroundImagePath(`https://backend.kbascontracting.com/${response.data.data.path}`);
      })
      .catch(error => {
        console.error('There was an error fetching the background image', error);
      });
  }, []);

  return (
    <div className="relative text-white text-center">
      {/* Background image */}
      <img 

src={backgroundImagePath} 
    alt="Background" 
    className="absolute w-full h-full object-cover z-0" 
  />

  {/* Overlay */}
  <div className="absolute w-full h-full bg-black bg-opacity-90 z-10 xl:px-80" />

  {/* Content */}
  <div className="container mx-auto z-20 flex flex-col md:flex-row justify-center items-center h-3/4 md:h-1/3 space-x-0 px-14 md:space-x-5 relative sm:px-6 md:px-0 lg:px-10 xl:px-80 ">
    {sections.map(section => (
      <div key={section.id} className="mb-10 md:mb-10 mt-10 ml-5 mr-5">
        <img src={`https://backend.kbascontracting.com/${section.iconImage}`} alt={section.title} className="mx-auto mb-4 mb-5" />
        <h3 className="text-md font-bold pb-2 mt-5">{section.title}</h3>
        <p className="pb-5 mt-5" style={{ fontSize: '13.5px', lineHeight: '1.5', textAlign: 'center' }}>
          {section.paragraph}
        </p>
        {/* <Link to="/feature" className="text-orange-500 hover:text-orange-300">Read More</Link> */}
      </div>
    ))}
  </div>
</div>

);
};

export default FeatureSection;