import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const InfoBar = () => {
  const [infoBarData, setInfoBarData] = useState({
    logo: '',
    icon: '',
    phone: '',
    email: ''
  });

  useEffect(() => {
    axios.get('https://backend.kbascontracting.com/InfoBar/list')
      .then(response => {
        // Assuming the API returns data in the format you need
        if (response.data.success && response.data.data.length > 0) {
          // Assuming the first element in the data array has the info
          const data = response.data.data[0];
          setInfoBarData({
            logo: `https://backend.kbascontracting.com/${data.logo}`,
            icon: `https://backend.kbascontracting.com/${data.icon}`,
            phone: data.phone,
            email: data.email
          });
        }
      })
      .catch(error => {
        console.error('Error fetching InfoBar data', error);
      });
  }, []);

  return (
    <div className="flex justify-center items-center mb-12 space-x-20 xl:space-x-12  xl:mt-2 py-2 px-2 xl:mb-12 ">
     
      <div className="flex items-center xl:-mt-0 xl:pr-80 m-2">
      <Link to="/home">
        <img src={infoBarData.logo}  className="w-20 h-auto" />
        </Link>
      </div>

      {/* Information Section */}
      <div className="flex justify-center sm:justify-start items-center space-x-2 xl:pl-40 ">
        <img src={infoBarData.icon} alt="" className="h-5 w-5 " />
        <div className="text-center sm:text-left">
          <p className="font-semibold text-sm">{infoBarData.phone}</p>
          <p className="text-sm text-gray-500">{infoBarData.email}</p>
        </div>
      </div>
    </div>
  );
};

export default InfoBar;
