import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MenuIcon from '../Images/more.png'; // Replace with your actual menu icon path
import CloseIcon from '../Images/more.png'; // Replace with your actual close icon path

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    axios.get('https://backend.kbascontracting.com/navbar/list')
      .then(response => {
        if (response.data.success) {
          setMenuItems(response.data.data);
        }
      })
      .catch(error => {
        console.error('Error fetching navbar items', error);
      });
  }, []);

  // Function to split the menuItems into chunks of 7
  const chunkedMenuItems = () => {
    const chunkSize = 7;
    let result = [];
    for (let i = 0; i < menuItems

.length; i += chunkSize) {
result.push(menuItems.slice(i, i + chunkSize));
}
return result;
};

const handleMouseEnter = (item) => {
  setHoveredItem(item.name);
};

const handleMouseLeave = () => {
  setHoveredItem(null);
};

return (
  <>
    <nav

className="bg-black text-white p-3 -mt-12 relative z-20">
{/* Mobile Menu Toggle */}
<div className="md:hidden pl-5" onClick={() => setIsMenuOpen(!isMenuOpen)}>
<img src={isMenuOpen ? CloseIcon : MenuIcon} alt={isMenuOpen ? 'Close' : 'Menu'} />
</div>


  {/* Desktop Menu Items */}
  <div className="hidden md:flex flex-col items-center text-xs uppercase tracking-widest">
    {/* Render desktop menu items */}
    {chunkedMenuItems().map((chunk, chunkIndex) => (
      <div key={chunkIndex} className="flex justify-center mt-2">
        {chunk.map((item, index) => (
          <a key={index} href={item.link}
             className={`px-5 py-2 border-white text-left ${index < chunk.length - 1 ? 'border-r' : ''}`}
             onMouseEnter={() => handleMouseEnter(item)}
             onMouseLeave={handleMouseLeave}
             style={hoveredItem === item.name ? { color: '#c57b2b' } : null}>
{item.name}
</a>
))}
</div>
))}
</div>



  {/* Mobile Menu Items */}
  {isMenuOpen && (
    <ul className="flex flex-col justify-center items-center text-uppercase tracking-widest absolute bg-black w-screen left-0 top-full z-30">
      {menuItems.map((item, index) => (
        <li key={index} className="w-full px-5 py-2 border-b border-white text-center">
          <a href={item.link}
             onMouseEnter={() => handleMouseEnter(item)}
             onMouseLeave={handleMouseLeave}
             style={hoveredItem === item.name ? { color: '#c57b2b' } : null}>
            {item.name}
          </a>
        </li>
      ))}
    </ul>
  )}

  {/* Overlay for Mobile Menu */}
  {isMenuOpen && (
    <div className="fixed inset-0 bg-black bg-opacity-75 z-20" onClick={() => setIsMenuOpen(false)}></div>
  )}
</nav>
</>

);
};

export default Navbar;



