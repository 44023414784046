import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ServicesSection = () => {
  const [sectionTitle, setSectionTitle] = useState('');
  const [sectionDescription, setSectionDescription] = useState('');
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetching section title and description
        const responseServices = await axios.get('https://backend.kbascontracting.com/Services/list');
        if (responseServices.data && responseServices.data.data && responseServices.data.data.length > 0) {
          const sectionInfo = responseServices.data.data[0];
          setSectionTitle(sectionInfo.title);
          setSectionDescription(sectionInfo.paragraph);
          setServices(responseServices.data.data.slice(1));
        }

        // Fetching services data
        const responseServicesSection = await axios.get('https://backend.kbascontracting.com/ServicesSection/list');
        if (responseServicesSection.data && responseServicesSection.data.data) {
          setServices(responseServicesSection.data.data.slice(0, 6));
        }

      } catch (error) {
        setError('There was an error fetching the data.');
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  
return (
<section className="bg-white">
  <div className="container mx-auto py-16 px-4 sm:px-6 md:px-0 lg:px-10 xl:px-80">
    <h2 className="text-2xl md:text-3xl text-center text-orange-500">{sectionTitle}</h2>
    <p className="text-sm md:text-base text-center text-gray-600 mt-4">{sectionDescription}</p>
    <hr className="border-2 w-14 border-orange-500 mx-auto mt-3" />

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-8 px-4 md:px-10">
      {services.map((service, index) => (
        <div to="/services" key={index} className="shadow-lg overflow-hidden">
          <div className="relative" style={{ height: '250px' }}>
            <img 
              src={`https://backend.kbascontracting.com/${service.mainImage}`}
              alt="" 
              className="object-cover w-full h-full"
            />
            <div className="absolute w-full h-full top-0 left-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}></div>
            <div className="absolute border-2 border-orange-500 top-0 w-full h-full flex flex-col items-center justify-center p-4 md:p-6">
              {/* <img src={`https://backend.kbascontracting.com/${service.iconImage}`} alt="" className="h-12 mb-4" /> */}
              <h3 className="text-sm md:text-md font-semibold text-white mb-2">{service.title}</h3>
              {/* <p className="text-xs md:text-sm text-white text-center">{service.paragraph}</p> */}
            </div>
          </div>
          </div>
      ))}
    </div>

    {/* Read More Link */}
    <div className="text-center mt-8">
      <Link to="/services" className="text-orange-500 hover:text-orange-300">Read More</Link>
    </div>
  </div>
</section>


);
};

export default ServicesSection;