import React, { useState, useEffect } from 'react';

const FullWidthHeightImage = () => {
  const [imagePath, setImagePath] = useState('');

  useEffect(() => {
    // Fetch image data from the server
    fetch('https://backend.kbascontracting.com/FullImage/list') // Replace with your actual API URL
      .then(response => response.json())
      .then(data => {
        // Assuming the server returns an array of images and you want to display the first one
        if (data.success && data.data.length > 0) {
          setImagePath(data.data[0].path);
        }
      })
      .catch(error => {
        console.error('Error fetching image fromthe backend:', error);
});
}, []); 
return (
<div className="relative w-full h-screen overflow-hidden">
{imagePath && (
<img src= {`https://backend.kbascontracting.com/${imagePath}`}
       alt=""
       className="absolute inset-0 w-full h-full object-cover object-center"
     />
)}
</div>
);
};

export default FullWidthHeightImage;