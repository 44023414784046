import React from 'react';
import image1 from '../Images/civil-engineer-construction-worker-manager-holding-digital-tablet-blueprints-talking-planing-about-construction-site-cooperation-teamwork-concept.jpg';
import image2 from '../Images/2.jpg';
import image3 from '../Images/pexels-pixabay-33192.jpg';
import image4 from '../Images/pexels-tom-fisk-1427107.jpg';
import image5 from '../Images/factory.jpg';
import image6 from '../Images/pexels-photo-3855962.jpeg';
const Services = () => {
  return (
    <>
      <div className="flex flex-col px-5  max-w-7xl mx-auto my-5 mt-20 xl:px-20">
      <h2 className="text-2xl font-bold uppercase tracking-wider text-orange-500">Our Services</h2>
        <h1 className="text-xl font-bold text-gray-900 mt-7">Our Comprehensive Services</h1>
        <hr className="border-t-4 border-orange-500 w-1/5 mt-7" />
      </div>

      <div className="max-w-6xl mx-auto my-20 ">
        {/* Construction Section */}
        <ServiceSection
          title="Construction"
          description="Embark on a journey with KBAS, where we turn architectural visions into tangible structures. Our team of skilled builders and craftsmen ensures that each project, whether residential or commercial, is executed with precision, quality, and meticulous attention to detail."
          image={image1}
        />
        
        <ServiceSection
          title="Contracting"
          description="As your reliable contracting partner, KBAS oversees every aspect of your project from inception to completion. Our comprehensive contracting services include project planning, budgeting, procurement, and construction oversight, ensuring a seamless and successful project delivery."
          image={image2}
        />

        <ServiceSection
          title="Engineering"
          description="Engineering excellence is at the heart of KBAS. Our team of proficient engineers employs the latest technologies and industry best practices to design and implement solutions that meet the highest standards. From structural design to infrastructure development, count on us for innovative engineering solutions."
          image={image3}
        />

        <ServiceSection
          title="Supply Chain Management"
          description="At KBAS, we understand the critical role of efficient supply chain management in project success. Our streamlined processes and optimized logistics ensure the seamless flow of materials, keeping your projects on schedule and within budget."
          image={image4}
        />

        <ServiceSection
          title="Oil & Gas"
          description="In the dynamic realm of oil and gas, KBAS is your trusted partner. Our specialized services cater to the unique demands of this industry, offering solutions that prioritize safety, compliance, and efficiency. From exploration to production, we have the expertise to navigate the complexities of the oil and gas sector."
          image={image5}
        />

        <ServiceSection
          title="Refining"
          description="KBAS Construction Contracting extends its expertise to the refining sector, providing comprehensive solutions for the development and enhancement of refining facilities. Our team understands the intricacies of refining processes and works diligently to deliver projects that contribute to the growth and efficiency of your operations."
          image={image6}
        />
      </div>
    </>
  );
};

const ServiceSection = ({ title, description, image }) => {
  return (
    <div className="flex flex-col lg:flex-row justify-between items-center my-8 mt-20">
      <div className="lg:w-1/2 p-8 mb-20">
        <h2 className="text-2xl font-semibold mb-6">{title}</h2>
        <p className="text-gray-700" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{description}</p>
      </div>
      <div className="lg:w-1/3 mt-7">
        <img src={image} alt={title} className="w-96 h-64 rounded-lg" />
      </div>
    </div>
  );
};

export default Services;
