import React from 'react';

const UnderFooter = () => {
  return (
    <footer className="text-white" style={{ backgroundColor: '#2e2e2e' }}>
      <div className="container mx-auto py-4 flex flex-wrap justify-between items-center px-4 sm:px-6 md:px-10 lg:px-20 xl:px-80">
        <div>
          <span className="text-sm">Copyrights © 2024</span>
        </div>
        <div className="flex space-x-4">
          <a href="/PrivacyPolicy" className="text-sm text-gray-400 hover:text-gray-200">
            Privacy Policy
          </a>
<a href="/TermsAndConditions" className="text-sm text-gray-400 hover:text-gray-200">
Terms & Conditions
</a>
</div>
</div>
</footer>
);
};

export default UnderFooter;