import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Import your page components
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Projects from './Pages/Projects';
import Careers from './Pages/Careers';
import News from './Pages/News';
import Feature from './Pages/Feature';
import ContactUs from './Pages/ContactUs';

// Import your layout components
import SearchHeader from './Component/SearchHeader';
import InfoBar from './Component/InfoBar';
import Navbar from './Component/Navbar'; // Replace with your actual navbar component
import Footer from './Component/Footer';
import UnderFooter from './Component/UnderFooter';
import PrivacyPolicy from './Component/PrivacyPolicy';
import TermsAndConditions  from './Component/TermsAndConditions';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        {/* Layout components that will appear on all pages */}
        {/* <SearchHeader /> */}
        <InfoBar />
        <Navbar />

        {/* Route-specific components */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/news" element={<News />} />
          <Route path="/feature" element={<Feature />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions  />} />
          <Route path="/contact" element={<ContactUs  />} />
        </Routes>

        {/* Layout components that will appear on all pages */}
        <Footer />
        <UnderFooter />
      </BrowserRouter>
    </div>
  );
};

export default App;
