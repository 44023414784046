import React from 'react';
import ContactImage from '../Images/1.jpg'; // Replace with actual image path

const ContactUs = () => {
    return (
        <div className="flex justify-center items-center mt-20 mb-40 px-4">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between max-w-4xl space-y-8 md:space-y-0 md:space-x-60">
          <div className="md:w-1/2 lg:w-2/5 space-y-6 ">
            <h1 className="text-3xl font-bold text-gray-800">Contact Us</h1>
            <p className="text-gray-600">Feel free to reach out to us with any inquiries or feedback. Our team is always ready to assist you.</p>
            <div className="space-y-4">
              <div className="mt-10">
                <strong>Email:</strong> <a href="info@kbascontracting.com" className="text-blue-600">info@kbas.com</a>
              </div>
              <div>
                <strong>Phone:</strong> <a href="tel:+962775885888" className="text-blue-600">(+962) 775885888 </a>
              </div>
          
            </div>
          </div>
          <div className="md:w-1/2 lg:w-3/5 mt-8 md:mt-0">
            <img src={ContactImage} alt="Contact" className="max-w-96 h-auto rounded-lg shadow-lg"/>
          </div>
        </div>
      </div>
      );
    }

export default ContactUs;
