import React, { useState, useEffect } from 'react';
import axios from 'axios';


import FullWidthHeightImage from '../Component/FullWidthHeightImage';
import FeatureSection from '../Component/FeatureSection';
import ServicesSection from '../Component/ServicesSection';
// import LatestProjects from '../Component/LatestProjects';
import Latestnews from '../Component/Latestnews';





const Home = () => {

    return (
<div>

<FullWidthHeightImage />
<FeatureSection />
<ServicesSection />
{/* <LatestProjects /> */}
<Latestnews />


</div>


        );
};

export default Home;