import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-4 mt-20 my-20 ">
      <h1 className="text-3xl font-bold mb-20">Privacy Policy for KBAS Construction Contracting</h1>
      
      
      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10 pl-6">1. Introduction</h2>
      <p className="pl-6 mb-4">Welcome to KBAS Construction Contracting. We are committed to protecting the privacy and security of our customers and site visitors. This Privacy Policy explains how we collect, use, and share information about you when you interact with us, whether through our website, via email, or through our services.</p>
      
      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10">2. Information We Collect</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2"><strong>Personal Identification Information:</strong> Name, email address, phone number, and mailing address.</li>
        <li className="mb-2"><strong>Engagement Data:</strong> Information related to your interactions with our website, services, emails, and customer service communications.</li>
        <li className="mb-2"><strong>Transactional Data:</strong> Details about the services you have purchased from us.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10">3. How We Use Your Information</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">To provide and maintain our services.</li>
        <li className="mb-2">To communicate with you, including responding to your inquiries.</li>
        <li className="mb-2">To enhance our website and services.</li>
        <li className="mb-2">For internal record keeping and billing purposes.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10">4. Sharing Your Information</h2>
      <p className="mb-4">We do not sell, distribute, or lease your personal information to third parties unless we have your permission or are required by law to do so. We may share your information with trusted third parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10">5. Data Security</h2>
      <p className="mb-4">We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2mt-10">6. Your Rights</h2>
      <p className="mb-4">You have the right to access, correct, or delete your personal data held by us. </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2 mt-10">7. Changes to This Privacy Policy</h2>
      <p className="mb-4">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>


    </div>
  );
}

export default PrivacyPolicy;
